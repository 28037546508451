import {Uuids, WebBluetoothConnectedDevice} from 'pikaparam';

import {Constants} from '../../../../constants';
import {Alert} from '../../../../shims/alert';
import {bleLog} from '../../../Logger';
import {sleep} from '../../../sleep';
import {OtaDevice} from '../OtaDevice';
import {ConnectionError} from '../types';
import {
  BleManagerBase,
  ConnectOptions,
  IBleManager,
  OtaConnectOptions,
  ScanOptions,
} from './BleManagerBase';

const nav: Puffco.Navigator = window.navigator;

interface PuffcoBluetoothLEScanFilter extends BluetoothLEScanFilter {
  deviceId?: string;
}

export class BleManager
  extends BleManagerBase<BluetoothDevice, WebBluetoothConnectedDevice>
  implements IBleManager<BluetoothDevice>
{
  private scanner?: {stop: () => Promise<void>};

  protected async createConnectedDevice(device: BluetoothDevice) {
    return await WebBluetoothConnectedDevice.create(device, this.logger, {
      timeout: 10000,
    });
  }

  protected async bond(device: WebBluetoothConnectedDevice) {
    bleLog.info('Bonding.', {peripheralId: device.id});

    await device.triggerBonding();
  }

  // MTU is not supported on web
  protected async requestMtu() {}

  async isConnected(peripheralId: string) {
    return this.peak?.peripheralId === peripheralId && this.peak?.connected;
  }

  private async scan(filters: PuffcoBluetoothLEScanFilter[], timeout: number) {
    this.logger.info('Scanning devices using filters.', {filters});

    const device = await this.runAfterUserInteraction(async () => {
      const timeoutId = setTimeout(async () => {
        await this.stopScan();
      }, timeout);

      return nav.bluetooth
        ?.requestDevice({
          filters,
          optionalServices: [Uuids.pupService, Uuids.silabsOtaService],
        })
        .catch(error => {
          if (typeof error === 'string') throw new Error(error);
          throw error;
        })
        .finally(() => clearTimeout(timeoutId));
    });

    return device;
  }

  async stopScan() {
    await nav.bluetooth?.stopRequest?.();
  }

  async scanForDevice({
    timeout = BleManagerBase.SCAN_TIMEOUT,
  }: ScanOptions): Promise<BluetoothDevice> {
    this.logger.info('Scan and connect.', {timeout});

    const filters = [
      {services: [Uuids.pikachuService]},
      {services: [Uuids.loraxService]},
      ...Uuids.silabsOuis.map(namePrefix => ({namePrefix})),
      ...Uuids.atmosicOuis.map(namePrefix => ({namePrefix})),
    ];

    // We will wait for 1 min because the user has to select a device
    const device = await this.scan(filters, 60000);

    return this.validateDevice(device);
  }

  async connectToDevice(
    device: BluetoothDevice,
    o: ConnectOptions,
  ): Promise<void> {
    bleLog.info('Connect device.', {
      peripheralId: device.id,
      name: device.name,
    });

    await this.connectWithPikaparam(device, o);
  }

  async connect(
    peripheralId: string,
    name: string | undefined,
    {timeout = BleManager.SCAN_TIMEOUT, ...o}: ConnectOptions,
  ): Promise<void> {
    this.logger.info('Connect.', {peripheralId, timeout});

    const filters = [
      ...(Constants.IS_USING_PATH_BROWSER
        ? [{deviceId: peripheralId}, ...(name ? [{deviceId: 'x', name}] : [])]
        : // In the browser we cannot filter by peripheralId so we will display all possible devices
          [
            Uuids.loraxService,
            Uuids.pikachuService,
            Uuids.pupService,
            Uuids.silabsOtaService,
          ].map(service => ({services: [service]}))),
      ...Uuids.silabsOuis.map(namePrefix => ({namePrefix})),
      ...Uuids.atmosicOuis.map(namePrefix => ({namePrefix})),
    ];

    const device = await this.scan(filters, timeout);

    await this.connectWithPikaparam(this.validateDevice(device), o);
  }

  async scanForAdvertisements(
    // eslint-disable-next-line
    callback: (device: any, event: any) => void,
  ): Promise<void> {
    // const peakFilter = [{services: [Uuids.loraxService]}];
    // await nav.bluetooth?.addEventListener('advertisementreceived', event => {
    //   const {device, manufacturerData} = event.data;
    //   const md: ArrayBuffer = manufacturerData.get(Constants.COMPANY_ID);
    //   if (!md) {
    //     return;
    //   }
    //   callback(device, Buffer.from(md));
    // });
    // await this.scanner?.stop();
    // this.scanner = await this.runAfterUserInteraction(() =>
    //   nav.bluetooth?.requestLEScan({filters: peakFilter}),
    // );
  }

  async stopScanForAdvertisements(): Promise<void> {
    this.logger.info('stop advertisement scan');
    await this.scanner?.stop();
    this.scanner = undefined;
  }

  private async runAfterUserInteraction<T>(callback: () => T) {
    if (Constants.IS_USING_PATH_BROWSER) return callback();

    if (nav.userActivation?.isActive) return callback();

    return new Promise<void>(resolve => {
      // Sleep is required, otherwise the alert is not displayed on web
      sleep(100).then(() => {
        Alert.alert(
          'Manual interaction required',
          'Click the button to continue',
          [{text: 'Continue', onPress: () => resolve()}],
          {onDismiss: () => resolve()},
        );
      });
    }).then(() => callback());
  }

  async otaConnect({name}: OtaConnectOptions): Promise<OtaDevice> {
    bleLog.info('Ota connect.', {name});

    // We don't use {deviceId: peripheralId} anymore because iOS seems to be caching the device
    // during the scan and thinks it found it, but we can never connect to it because it's
    // not there. So we just scan for {name: euid} like on the Android side.
    // We still still need to pass some value for {deviceId: 'x'} to trigger our non-interactive
    // reconnect process (non-standard).
    const filters = [
      ...(name
        ? Constants.IS_USING_PATH_BROWSER
          ? [{deviceId: 'x', name}]
          : [{name}]
        : []),
      ...Uuids.silabsOuis.map(namePrefix => ({namePrefix})),
      ...Uuids.atmosicOuis.map(namePrefix => ({namePrefix})),
      {services: [Uuids.pupService]},
      {services: [Uuids.silabsOtaService]},
    ];

    const device = await this.scan(filters, BleManager.SCAN_TIMEOUT);

    const {ota} = await this.connectWithPikaparam(this.validateDevice(device));

    return ota;
  }

  private validateDevice(device?: BluetoothDevice) {
    // Path Browser might return an invalid device when stopRequest has been called
    // so we will check for the existance of the device id.
    if (!device?.id) throw new Error(ConnectionError.DEVICE_NOT_FOUND);

    return device;
  }

  protected async setupDevice(): Promise<void> {}
}

export const bleManager: IBleManager<BluetoothDevice> = new BleManager();
