import type {Asset, Entry, EntryFields} from 'contentful';

import type {TypeGuideStepFields} from './TypeGuideStep';
import type {WithContentTypeLink} from './WithContentTypeLink';

export interface TypeGuideFields {
  name: EntryFields.Symbol;
  title: EntryFields.Symbol;
  type: 'DEVICE' | 'NEW_USER';
  image: Asset;
  steps?: Entry<TypeGuideStepFields>[];
  manual?: Asset;
}

export type TypeGuide = Entry<TypeGuideFields>;

export function isTypeGuide(entry: WithContentTypeLink): entry is TypeGuide {
  return entry?.sys?.contentType?.sys?.id === 'guide';
}
