import React from 'react';
import {Animated, Image, SectionList, Text, View} from 'react-native';
import {Swipeable, TouchableOpacity} from 'react-native-gesture-handler';
import {useSelector} from 'react-redux';

import {
  AppText,
  BlurredBackgroundSafeArea,
  ProgressBar,
  SectionTitle,
  StyledButton,
} from '../../components';
import {
  Alerts,
  Constants,
  Navigators,
  RECORDS,
  Screens,
  Strings,
  appColors,
} from '../../constants';
import {Connection} from '../../contexts/useConnection';
import {useAppDispatch, useWatchDevice} from '../../lib/hooks';
import {useAdaptiveSafeArea} from '../../lib/hooks/useAdaptiveSafeArea';
import {appSettingsSelector} from '../../lib/redux/appSettingsSlice';
import {
  currentDeviceSelector,
  devicesSelector,
  removeBleDevice,
  updateDeviceInfo,
} from '../../lib/redux/bleSlice';
import styled from '../../lib/styled';
import {BatteryChargeState, Device} from '../../lib/types';
import {meetsMinimumFirmware} from '../../lib/utilityFunctions';
import {getProductName} from '../../lib/utilityFunctions/getProductName';
import {DevicesListNavigatorScreenProps} from '../../navigation/navigators/HomeDrawerNavigator';
import {Alert} from '../../shims/alert';
import {AnimationSwipeContainer} from './components/AnimationSwipeContainer';
import {SwipeRightActions} from './components/SwipeRightActions';

const {
  CANCEL,
  CHARGING,
  MY_COLLECTIONS,
  CHAMBER_DISCONNECTED,
  CHARGING_TO_EIGHTY,
  DISCONNECTED,
  FULLY_CHARGED,
  INSUFFICIENT_BATTERY,
  NOT_BROADCASTING,
  DELETE_DEVICE,
} = Strings;

// TODO: move this to the advertisement scanner logic
const useOutOfRangeCleanup = () => {
  const outOfRangeTimeout = 60000;

  const {peak} = Connection.useContainer();

  const connected = !!peak;
  const currentDevice = useSelector(currentDeviceSelector);
  const devices = useSelector(devicesSelector);
  const dispatch = useAppDispatch();
  const [counter, setCounter] = React.useState(0);

  React.useEffect(() => {
    const interval = setInterval(
      () => setCounter(counter => counter + 1),
      10000,
    );

    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    const now = Date.now();

    devices.forEach(device => {
      if (connected && device.id === currentDevice?.id) return;

      if (!device.lastAdvertisement) return;
      if (device.battery === undefined) return;

      if (now - device.lastAdvertisement <= outOfRangeTimeout) return;

      dispatch(
        updateDeviceInfo({
          id: device.id,
          battery: undefined,
          batteryChargeState: undefined,
          approxDabsRemainingCount: undefined,
        }),
      );
    });
  }, [connected, devices, currentDevice?.id, counter]);
};

interface DeviceCardHandlerProps {
  device: Device;
  isCurrentDevice: boolean;
  onTap(): void;
  onConfirm(): Promise<boolean>;
  onDelete(): void;
}

const DeviceCardHandler: React.FC<
  React.PropsWithChildren<DeviceCardHandlerProps>
> = ({isCurrentDevice, device, children, onTap, onConfirm, onDelete}) => {
  const ref = React.useRef<Swipeable>(null);

  return (
    <AnimationSwipeContainer
      key={device.id}
      onClick={async () => {
        return onConfirm()
          .catch(() => false)
          .then(confirmed => {
            if (!confirmed) ref.current?.close();
            return confirmed;
          });
      }}
      onAfterAnimation={() => onDelete()}>
      {({style: {height, top, width, right}, onPress, setLayout}) => {
        const borderWidth = isCurrentDevice
          ? height
            ? top.interpolate({
                inputRange: [0, height],
                outputRange: [3, 0],
              })
            : 3
          : 0;
        const styles = {
          height: height
            ? top.interpolate({
                inputRange: [0, height],
                outputRange: [height + (isCurrentDevice ? 3 : 0), 0],
              })
            : undefined,
          opacity: height
            ? top.interpolate({
                inputRange: [0, height],
                outputRange: [1, 0],
              })
            : 1,
          marginTop: height
            ? top.interpolate({
                inputRange: [0, height],
                outputRange: [10, 0],
              })
            : 10,
          paddingVertical: height
            ? top.interpolate({
                inputRange: [0, height],
                outputRange: [16, 0],
              })
            : 16,
          paddingLeft: height
            ? top.interpolate({
                inputRange: [0, height],
                outputRange: [18, 0],
              })
            : 18,
          paddingRight: height
            ? top.interpolate({
                inputRange: [0, height],
                outputRange: [24, 0],
              })
            : 24,
        };
        return (
          <Swipeable
            {...{ref}}
            renderRightActions={(progress, dragX) => (
              <SwipeRightActions
                {...{progress, dragX, onPress}}
                width={
                  width
                    ? right.interpolate({
                        inputRange: [0, width / 2 + 10],
                        outputRange: [width / 2, width],
                      })
                    : undefined
                }
                opacity={
                  height
                    ? top.interpolate({
                        inputRange: [0, height],
                        outputRange: [1, 0],
                      })
                    : undefined
                }
              />
            )}
            overshootRight={false}>
            <TouchableOpacity onPress={() => onTap()} activeOpacity={1}>
              <SectionItem
                style={{
                  backgroundColor: appColors.coal,
                  borderWidth,
                  right,
                  ...styles,
                }}
                onLayout={e => {
                  const {height, width} = e.nativeEvent.layout;
                  setLayout(width, height);
                }}>
                {children}
              </SectionItem>
            </TouchableOpacity>
          </Swipeable>
        );
      }}
    </AnimationSwipeContainer>
  );
};

interface Props
  extends DevicesListNavigatorScreenProps<typeof Screens.DevicesList> {}

export const DevicesListScreen = ({navigation}: Props) => {
  const dispatch = useAppDispatch();
  useAdaptiveSafeArea();

  const currentDevice = useSelector(currentDeviceSelector);

  const savedDevices = useSelector(devicesSelector);
  const {peak, progress, connecting, disconnect, disconnecting} =
    Connection.useContainer();

  const connected = !!peak;

  const {batteryPreservationOn} = useSelector(appSettingsSelector);

  useWatchDevice('battery');
  useWatchDevice('chamberType');
  useWatchDevice('batteryChargeEstTimeToFull');
  useWatchDevice('batteryChargeState');
  useWatchDevice('approxDabsRemainingCount');

  useOutOfRangeCleanup();

  React.useEffect(() => {
    navigation.setOptions({
      headerTitle: Strings.DEVICES,
      headerTitleStyle: {
        fontSize: 16,
        fontFamily: 'Roboto-Bold',
        fontWeight: '400',
        textTransform: 'uppercase',
      },
    });
  }, []);

  const showForgetAlert = () => {
    return new Promise<boolean>(resolve =>
      Alert.alert(
        Alerts.CONFIRM_DELETE_DEVICE_TITLE,
        Alerts.CONFIRM_DELETE_DEVICE_BODY,
        [
          {text: CANCEL, style: 'cancel', onPress: () => resolve(false)},
          {text: DELETE_DEVICE, onPress: () => resolve(true)},
        ],
        {onDismiss: () => resolve(false), cancelable: true},
      ),
    );
  };

  const addDevice = React.useCallback(
    () =>
      navigation.navigate(Screens.PairInstructions, {
        redirect: [
          Navigators.MainNavigator,
          {
            screen: Navigators.HomeDrawerNavigator,
            params: {
              screen: Navigators.HomeEmulatedDrawer,
              params: {
                screen: Navigators.DevicesList,
                params: {
                  screen: Screens.DevicesList,
                },
              },
            },
          },
        ],
      }),
    [navigation.navigate],
  );

  const reconnectToDevice = React.useCallback(
    (device: Device) =>
      navigation.navigate(Screens.Connect, {
        deviceId: device.id,
        redirect: [
          Navigators.MainNavigator,
          {
            screen: Navigators.HomeDrawerNavigator,
            params: {
              screen: Navigators.HomeEmulatedDrawer,
              params: {
                screen: Navigators.DevicesList,
                params: {
                  screen: Screens.DevicesList,
                },
              },
            },
          },
        ],
      }),
    [navigation.navigate],
  );

  const onDelete = React.useCallback(
    async (device: Device) => {
      if (peak?.peripheralId === device.id) await disconnect();

      dispatch(removeBleDevice(device.id));

      if (savedDevices.length > 1) return;

      addDevice();
    },
    [addDevice, savedDevices.length, peak, disconnect],
  );

  const isChargingState = (device?: Device) => {
    return (
      device?.batteryChargeState === BatteryChargeState.BULK ||
      device?.batteryChargeState === BatteryChargeState.TOPUP ||
      device?.batteryChargeState === BatteryChargeState.FULL
    );
  };

  const getDeviceProgressText = (device: Device) => {
    const minDabs = Math.floor((device.approxDabsRemainingCount ?? 0) / 5) * 5;
    if (
      !(connected && device.id === currentDevice?.id) &&
      device.broadcastingKey
    ) {
      // if not connected but is a broadcasting Peach, we can only show dabs remaining
      return `${minDabs}-${minDabs + 5} dabs remaining`;
    }
    const isCharging = isChargingState(device);

    if (isCharging) {
      if (
        device.batteryChargeEstTimeToFull &&
        device.batteryChargeEstTimeToFull !== Infinity
      ) {
        const {batteryChargeEstTimeToFull, battery, softwareRevisionString} =
          device;
        if (!battery || !batteryChargeEstTimeToFull) return '';
        const estTime =
          batteryPreservationOn &&
          meetsMinimumFirmware(
            softwareRevisionString,
            Constants.MINIMUM_FIRMWARE_VERSION.BATTERY_PRESERVATION,
          )
            ? Math.max(
                (batteryChargeEstTimeToFull *
                  (Constants.BATTERY_PRESERVATION_PCT - battery)) /
                  (100 - battery),
                0,
              )
            : batteryChargeEstTimeToFull;

        const remainMin = Math.round(estTime / 60);
        return `${remainMin} min until fully charged`;
      }
      if (device.battery && device.battery >= 95) return '0 min remaining';
      return '';
    }

    return `${minDabs}-${minDabs + 5} dabs remaining`;
  };

  const getDeviceState = (device: Device) => {
    if (device.id === connecting) {
      if (['starting', 'scanning'].includes(progress.data)) {
        return {color: appColors.progressBlue, text: `${Strings.SEARCHING}...`};
      }

      return {color: appColors.progressBlue, text: Strings.CONNECTING};
    }

    if (device.id === disconnecting)
      return {color: appColors.red, text: 'Disconnecting...'};

    // if not connected, and not advertising (ie pre-Peach)
    if (!connected && !device.broadcastingCounter)
      return {color: appColors.red, text: DISCONNECTED};

    const charging = isChargingState(device);

    if (charging) {
      if (device.battery === 100) {
        return {color: appColors.readyGreen, text: FULLY_CHARGED};
      }

      if (
        batteryPreservationOn &&
        meetsMinimumFirmware(
          device.softwareRevisionString,
          Constants.MINIMUM_FIRMWARE_VERSION.BATTERY_PRESERVATION,
        )
      ) {
        if (device.battery && device.battery >= 80) {
          return {color: appColors.readyGreen, text: FULLY_CHARGED};
        }

        return {color: appColors.readyGreen, text: CHARGING_TO_EIGHTY};
      }

      return {color: appColors.readyGreen, text: CHARGING};
    }

    if (device.battery === 0)
      return {color: appColors.red, text: INSUFFICIENT_BATTERY};

    if (!device.chamberType)
      return {color: appColors.red, text: CHAMBER_DISCONNECTED};

    // if connected, default idle state is ACTIVE
    if (connected && device.id === currentDevice?.id)
      return {color: appColors.readyGreen, text: 'Connected'};

    return {color: appColors.red, text: DISCONNECTED};
  };

  const displayPercentageStatus = (device: Device) => {
    if (!(connected && device.id === currentDevice?.id)) {
      // if not connected
      if (device.broadcastingKey) {
        if (device.battery !== undefined) {
          return `${device.battery}%`;
        } else {
          return '0%';
        }
      } else {
        return NOT_BROADCASTING;
      }
    }
    if (connected && device.id === currentDevice?.id) {
      return `${device.battery || 0}%`;
    }
    return 'N/A';
  };

  const toggleConnect = React.useCallback(
    async (device: Device) => {
      if (peak?.peripheralId === device.id) return await disconnect();

      return reconnectToDevice(device);
    },
    [peak, disconnect, reconnectToDevice],
  );

  return (
    <BlurredBackgroundSafeArea
      style={{
        backgroundColor: appColors.black,
        flex: 1,
        height: Constants.IS_WEB ? window.innerHeight : '100%',
      }}>
      <Container>
        <SectionListContainer>
          <SectionList
            sections={[{data: savedDevices, type: 'saved'}]}
            style={{width: '100%'}}
            contentContainerStyle={{paddingHorizontal: 15}}
            renderSectionHeader={() => (
              <SectionTitle
                title={`${MY_COLLECTIONS} (${savedDevices?.length ?? 0})`}
                titleStyle={{
                  fontFamily: 'Roboto-Regular',
                  fontWeight: '400',
                  color: appColors.white,
                }}
                fillerStyle={{backgroundColor: appColors.white20}}
                style={{width: '100%', marginHorizontal: 0}}
              />
            )}
            renderItem={({item: device}) => {
              const isCurrentDevice = currentDevice?.id === device.id;
              const deviceState = getDeviceState(device);

              return (
                <DeviceCardHandler
                  {...{device, isCurrentDevice}}
                  onTap={() => toggleConnect(device)}
                  onConfirm={showForgetAlert}
                  onDelete={() => onDelete(device)}>
                  <HorizontalContainer>
                    <ImageContainer>
                      <PeakImage
                        source={
                          RECORDS.THEME_RECORD[getProductName(device)]
                            .peakImageTheme.peak
                        }
                      />
                    </ImageContainer>
                    <DeviceInfoContainer>
                      <VerticalContainer style={{width: '100%'}}>
                        <DeviceNameText
                          numberOfLines={1}
                          style={{color: appColors.white}}>
                          {device?.name}
                        </DeviceNameText>
                        <View style={{flexDirection: 'row'}}>
                          <ConnectedContainer>
                            <ConnectionIcon
                              style={{backgroundColor: deviceState.color}}
                            />
                            <ConnectionStatusText
                              style={{color: appColors.white}}>
                              {deviceState.text}
                            </ConnectionStatusText>
                          </ConnectedContainer>
                        </View>
                        <BatteryPercentage
                          style={{
                            color:
                              (connected && isCurrentDevice) ||
                              displayPercentageStatus(device) ===
                                NOT_BROADCASTING ||
                              (!(connected && isCurrentDevice) &&
                                device.broadcastingKey &&
                                device.battery !== undefined)
                                ? appColors.baseText
                                : appColors.white20,
                          }}>
                          {displayPercentageStatus(device)}
                        </BatteryPercentage>
                        <ProgressBar
                          width={
                            (connected && isCurrentDevice) ||
                            (!(connected && isCurrentDevice) &&
                              device.broadcastingKey &&
                              device.battery !== undefined)
                              ? `${device?.battery || 0}%`
                              : '0%'
                          }
                          wrapperStyle={{
                            height: 15,
                            width: '100%',
                            padding: 2,
                            backgroundColor: appColors.white10,
                          }}
                          innerStyle={{
                            height: '100%',
                            position: 'relative',
                            backgroundColor: appColors.readyGreen,
                          }}
                          containerStyle={{
                            marginTop: 2,
                          }}
                          textStyle={{
                            fontSize: 12,
                            marginTop: 3,
                            color: appColors.white,
                            fontFamily: 'Roboto-Regular',
                            fontWeight: '400',
                          }}
                          text={getDeviceProgressText(device)}
                        />
                      </VerticalContainer>
                    </DeviceInfoContainer>
                  </HorizontalContainer>
                </DeviceCardHandler>
              );
            }}
          />
        </SectionListContainer>
        <ButtonContainer>
          <StyledButton
            disabled={!!disconnecting || !!connecting}
            style={{width: '100%'}}
            title={connecting ? 'Connecting...' : 'Add Device'}
            onPress={() => {
              if (!peak) return addDevice();

              Alert.alert(
                'Disconnect Current Device',
                'To add a new device, we need to disconnect from the current device. Continue?',
                [
                  {text: 'Cancel', style: 'cancel'},
                  {
                    text: 'Yes, disconnect.',
                    onPress: async () => {
                      await disconnect();
                      addDevice();
                    },
                  },
                ],
                {cancelable: false},
              );
            }}
          />
        </ButtonContainer>
      </Container>
    </BlurredBackgroundSafeArea>
  );
};

const Container = styled(View)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100%',
  width: '100%',
  paddingTop: 60,
  flex: 1,
});

const SectionListContainer = styled(View)({
  width: '100%',
  flex: 1,
});

const SectionItem = styled(Animated.View)({
  height: 'auto',
  borderRadius: 8,
  borderColor: appColors.sectionItemBorder,
  borderStyle: 'solid',
});

const HorizontalContainer = styled(View)({
  flexDirection: 'row',
  alignItems: 'center',
  flex: 1,
});

const VerticalContainer = styled(View)({
  flexDirection: 'column',
});

const DeviceNameText = styled(AppText)({
  fontSize: 18,
  fontFamily: 'Roboto-Bold',
  letterSpacing: 0.2,
  textTransform: 'uppercase',
  marginVertical: 0,
});

const ConnectedContainer = styled(View)({
  height: 20,
  borderRadius: 10,
  backgroundColor: appColors.black,
  flexDirection: 'row',
  alignItems: 'center',
  maxWidth: '100%',
  gap: 6,
  paddingHorizontal: 8,
  marginTop: 4,
});

const ConnectionIcon = styled(View)({
  width: 7,
  height: 7,
  borderRadius: 100,
  backgroundColor: appColors.green,
});

const ConnectionStatusText = styled(Text)({
  fontSize: 10,
  color: appColors.white,
  textTransform: 'uppercase',
  fontFamily: 'Roboto-Medium',
  fontWeight: '400',
});

const PeakImage = styled(Image)({
  resizeMode: 'contain',
  height: '100%',
  width: '100%',
});

const ImageContainer = styled(View)({
  height: '100%',
  width: 80,
  paddingTop: 7,
  marginLeft: -4,
});

const BatteryPercentage = styled(AppText)({
  fontSize: 24,
  marginTop: 12,
  marginVertical: 0,
});

const DeviceInfoContainer = styled(View)({
  flex: 1,
  flexDirection: 'row',
  marginLeft: 10,
});

const ButtonContainer = styled(View)({
  padding: 24,
  width: '100%',
});
