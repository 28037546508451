import React from 'react';
import {useSelector} from 'react-redux';

import {setUser as setLoggerUser} from './lib/Logger';
import {userApi} from './lib/api/apis';
import {useAppDispatch, useSyncPeakDevice} from './lib/hooks';
import {setUser, userSelector} from './lib/redux/userSlice';
import {setUser as setSentryUser} from './shims/sentry';

export const AppStateSensor: React.FC = () => {
  const dispatch = useAppDispatch();

  const user = useSelector(userSelector);

  useSyncPeakDevice();

  // TODO: remove this once session handling is fixed
  React.useEffect(() => {
    setLoggerUser(user ?? undefined);
    setSentryUser(user ?? undefined);
  }, [user]);

  React.useEffect(() => {
    if (!user?.id) return;

    userApi.getUserById({id: 'me'}).then(({data}) => dispatch(setUser(data)));
  }, [user?.id]);

  return null;
};
