import {useNavigation} from '@react-navigation/native';
import {isSilabsDeviceState} from 'pikaparam';
import {OtaEvent, OtaLatestGetResponse} from 'puffco-api-axios-client';
import React from 'react';
import {useSelector} from 'react-redux';

import {Navigators, Screens} from '../../../constants';
import {Connection} from '../../../contexts/useConnection';
import {otaLog} from '../../../lib/Logger';
import {otaApi} from '../../../lib/api/apis';
import {bleManager} from '../../../lib/ble2/v2/BleManager/BleManager';
import {FirmwareUpdatePhase} from '../../../lib/ble2/v2/BleManager/BleManagerBase';
import {downloadFirmware, useAppDispatch} from '../../../lib/hooks';
import {useProgress} from '../../../lib/hooks/useProgress';
import {
  devicesSelector,
  finishFirmwareUpdate,
  initiateFirmwareUpdate,
} from '../../../lib/redux/bleSlice';
import {Device} from '../../../lib/types';
import {linearInterpolate} from '../../../lib/utilityFunctions';
import {serializeDevice} from '../../../lib/utilityFunctions/serializeDevice';
import {HomeEmulatedDrawerStackScreenProps} from '../../../navigation/navigators/HomeDrawerNavigator';
import {RedirectionParameter} from '../../../navigation/navigators/params';
import {
  activateScreenAwake,
  deactivateScreenAwake,
} from '../../../shims/screenAwake';
import {setDevice, startSpan} from '../../../shims/sentry';

type FirmwareProgress = FirmwareUpdatePhase | 'downloading_firmware';

interface TrackOptions {
  serialNumber?: string;
  otaId: number;
  event: OtaEvent;
}

const track = async ({serialNumber, otaId, event}: TrackOptions) => {
  if (!serialNumber) return;

  const otaTrackingCreateDto = {
    otaTracking: {otaId, event},
    device: {serialNumber},
  };

  await otaApi.saveTracking({otaTrackingCreateDto}).catch(() => void 0);
};

const convertResponseToFirmware = ({
  id,
  version,
  fileMedia,
}: OtaLatestGetResponse) => ({
  id,
  version,
  url: fileMedia.originalUrl,
});

const getLatestFirmware = async (serialNumber?: string) => {
  otaLog.info('Get latest firmware.', {serialNumber});

  // If we have a serial number we can retrieve the firmware from the API
  if (serialNumber) {
    otaLog.info('Get latest firmware by serial number.', {serialNumber});

    return otaApi
      .getLatestOta({serialNumber})
      .then(r => convertResponseToFirmware(r.data));
  }

  // If we don't have an ota device set or not in bootloader state we can't proceed.
  if (!bleManager.otaDevice?.isInBootloaderMode())
    return otaLog.info('Device is not in bootloader mode. Skipping...');

  // Device is in bootloader state and serial number couldn't be determined, but
  // is not a silabs device so we can't proceed, because we don't know what's going on.
  if (!isSilabsDeviceState(bleManager.otaDevice.deviceState))
    return otaLog.info('Device is not a silabs device. Skipping...');

  otaLog.info('Get latest firmware by model.');

  // This is a silabs device, we pull the latest pikachu firmware.
  return (
    otaApi
      // TODO: move this magic value somewhere
      .getLatestOta({model: '21'})
      .then(r => convertResponseToFirmware(r.data))
  );
};

const getDeviceProperties = (devices: Device[]) => {
  const peak = bleManager.peak;

  if (peak) {
    const device = devices.find(d => d.id === peak.peripheralId);

    return {
      serialNumber: peak.serialNumber ?? device?.firmwareUpdate?.serialNumber,
      name: peak.euid,
      fromVersion:
        peak.softwareRevision ??
        device?.softwareRevisionString ??
        device?.firmwareUpdate?.fromVersion,
      device,
    };
  }

  const otaDevice = bleManager.otaDevice;

  if (otaDevice) {
    const device = devices.find(d => d.euid === otaDevice.name);

    return {
      serialNumber:
        otaDevice.serialNumber ?? device?.firmwareUpdate?.serialNumber,
      name: otaDevice.name,
      fromVersion:
        device?.softwareRevisionString ?? device?.firmwareUpdate?.fromVersion,
      device,
    };
  }

  return {
    serialNumber:
      devices[0]?.serialNumberString ??
      devices[0]?.firmwareUpdate?.serialNumber,
    name: devices[0]?.euid,
    fromVersion:
      devices[0]?.softwareRevisionString ??
      devices[0]?.firmwareUpdate?.fromVersion,
    device: devices[0],
  };
};

type Navigate = HomeEmulatedDrawerStackScreenProps<
  typeof Screens.FirmwareUpdating
>['navigation'];

export const useUpdateFirmware = () => {
  const {navigate} = useNavigation<Navigate>();
  const devices = useSelector(devicesSelector);

  const {preventReconnect} = Connection.useContainer();

  const [progress, setProgress] = useProgress<FirmwareProgress>({
    value: 0,
    duration: 0,
    data: 'not_started',
  });

  const dispatch = useAppDispatch();

  const start = React.useCallback(async () => {
    setProgress({value: 0, duration: 0, data: 'not_started'});

    otaLog.info('Start firmware update.');

    const now = Date.now();

    const {serialNumber, name, fromVersion, device} =
      getDeviceProperties(devices);

    otaLog.info('Device properties resolved.', {
      serialNumber,
      name,
      fromVersion,
    });

    return await startSpan({name: 'Firmware update'}, async scope => {
      const anyDevice = bleManager.peak ?? bleManager.otaDevice ?? device;

      if (anyDevice) setDevice(scope, serializeDevice(anyDevice));

      const ota = await startSpan({name: 'Get latest firmware'}, () =>
        getLatestFirmware(serialNumber),
      );

      setProgress({value: 0.05, data: 'downloading_firmware', duration: 1000});

      if (!ota) throw new Error('Firmware not available.');

      scope?.setTags({'ota.id': ota.id, 'ota.firmware': ota.version});

      await track({serialNumber, otaId: ota.id, event: OtaEvent.Started});

      activateScreenAwake().catch(() => void 0);

      setProgress({value: 0.1, data: 'downloading_firmware', duration: 2000});

      try {
        const firmware = await startSpan({name: 'Download firmware'}, () =>
          downloadFirmware(ota.url),
        );

        if (!firmware) throw new Error("Firmware couldn't be downloaded.");

        otaLog.info('Firmware downloaded.', {size: firmware.length});

        if (device) {
          preventReconnect();

          dispatch(
            initiateFirmwareUpdate({
              id: device.id,
              firmwareUpdate: {
                id: ota.id,
                fromVersion,
                toVersion: ota.version,
                serialNumber,
              },
            }),
          );
        }

        await startSpan({name: 'Flash firmware onto the device'}, async () => {
          await bleManager.updateFirmware({
            name,
            firmware,
            onProgress: progress =>
              setProgress({
                ...progress,
                value: linearInterpolate(
                  progress.value,
                  {min: 0, max: 1},
                  {min: 0.1, max: 1},
                ),
              }),
          });
        });

        setProgress({value: 1, data: 'done', duration: 1000});

        otaLog.info('Firmware update completed.', {
          otaId: ota.id,
          fromFirmware: fromVersion,
          firmware: ota.version,
          duration: Date.now() - now,
        });

        track({serialNumber, otaId: ota.id, event: OtaEvent.Succeeded});

        navigate(Screens.Connect, {
          deviceId: device?.id,
          redirect: new RedirectionParameter(Navigators.MainNavigator, {
            screen: Navigators.HomeDrawerNavigator,
            params: {
              screen: Navigators.HomeEmulatedDrawer,
              params: {
                screen: Screens.FirmwareUpdating,
              },
            },
          }).encode(),
        });

        dispatch(finishFirmwareUpdate());
      } catch (error) {
        track({serialNumber, otaId: ota.id, event: OtaEvent.Failed});

        otaLog.error('Firmware update failed.', {
          otaId: ota.id,
          fromFirmware: fromVersion,
          firmware: ota.version,
          duration: Date.now() - now,
          error,
        });

        throw error;
      } finally {
        deactivateScreenAwake().catch(() => void 0);
      }
    });
  }, [setProgress]);

  return React.useMemo(
    () => ({
      loading: !['not_started', 'done'].includes(progress.data),
      progress,
      start,
    }),
    [progress, start],
  );
};
