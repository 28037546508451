import {HeaderBackButtonProps} from '@react-navigation/elements';
import {StackActions, useFocusEffect} from '@react-navigation/native';
import React from 'react';
import {View} from 'react-native';
import {useSharedValue} from 'react-native-reanimated';
import {useSelector} from 'react-redux';

import {
  Analytics,
  CustomCategory,
  CustomEventAction,
} from '../../analytics/Analytics';
import {
  eye,
  eyeSlash,
  light,
  lightBulb,
  lightningBolt,
  power,
  rocket,
} from '../../assets/images';
import {
  BackButton,
  BlurredBackgroundSafeArea,
  FatButton,
  FatVerticalSlider,
  LargeRoundButton,
} from '../../components';
import {
  Alerts,
  Constants,
  Navigators,
  Screens,
  Strings,
  appColors,
} from '../../constants';
import {Connection} from '../../contexts/useConnection';
import {useAppDispatch} from '../../lib/hooks';
import {useAdaptiveSafeArea} from '../../lib/hooks/useAdaptiveSafeArea';
import {appFlagsSelector} from '../../lib/redux/appFlagsSlice';
import {
  connectedPeakSelector,
  currentDeviceSelector,
  updateDeviceSettings,
} from '../../lib/redux/bleSlice';
import {lanternMoodLightSelector} from '../../lib/redux/moodLightSlice';
import {userIdSelector} from '../../lib/redux/userSlice';
import styled from '../../lib/styled';
import {useDisconnectGuard} from '../../lib/useDisconnectGuard';
import {useTheme} from '../../lib/useTheme';
import {useThrottle} from '../../lib/useThrottle';
import {meetsMinimumFirmware} from '../../lib/utilityFunctions';
import {createInterpolate} from '../../lib/utilityFunctions/createInterpolate';
import {ControlCenterNavigatorScreenProps} from '../../navigation/navigators/HomeDrawerNavigator';
import {
  toControlCenter,
  toMoodLightLibrary,
} from '../../navigation/navigators/util';
import {Alert} from '../../shims/alert';
import {desertTheme} from '../../themes';

const {BRIGHTNESS_MAX, BRIGHTNESS_MIN, MINIMUM_FIRMWARE_VERSION} = Constants;

const CONTROL_CENTER_HEIGHT = 340;
const CONTROL_CENTER_WIDTH = 250;
const COMPONENT_DIAMETER = 70;
const BUTTON_WIDTH = 160;

const brightnessInterpolate = createInterpolate(
  [BRIGHTNESS_MIN, 0.15 * BRIGHTNESS_MAX, BRIGHTNESS_MAX],
  [0, 0.001, 1],
);

interface Props
  extends ControlCenterNavigatorScreenProps<typeof Screens.ControlCenter> {}

export const ControlCenterScreen = ({navigation}: Props) => {
  useDisconnectGuard();

  const theme = useTheme();
  const {navMenuIconColor, navMenuTitleStyle, controlCenterTheme} = theme;
  const {fatSliderBGColor, fatSliderColor} = controlCenterTheme;
  const dispatch = useAppDispatch();
  const peak = useSelector(connectedPeakSelector);
  const currentDevice = useSelector(currentDeviceSelector);
  const lanternMoodLight = useSelector(lanternMoodLightSelector);
  const loggedIn = !!useSelector(userIdSelector);
  const {hasSeenEpilepsyWarning} = useSelector(appFlagsSelector);

  const {preventReconnect} = Connection.useContainer();

  useAdaptiveSafeArea();

  const brightness = useSharedValue(
    brightnessInterpolate.from(currentDevice?.settings?.brightness ?? 0),
  );

  const lanternTimer = React.useRef<NodeJS.Timeout>();

  const isStealthModeOn = currentDevice?.settings?.stealth === 1;
  const isLanternModeOn = currentDevice?.settings?.lanternMode ?? false;
  const isReadyModeOn = currentDevice?.settings?.readyMode ?? false;
  const partyMode = currentDevice?.settings?.partyMode;
  const lanternPattern = currentDevice?.settings?.lanternPattern;
  const lanternColor = currentDevice?.settings?.lanternColor;

  const lanternPatternRef = React.useRef(lanternPattern);
  const lanternColorRef = React.useRef(lanternColor);

  const isLedApi2 = meetsMinimumFirmware(
    currentDevice?.softwareRevisionString,
    MINIMUM_FIRMWARE_VERSION.MOOD_LIGHTING,
  );
  const isDesert = theme === desertTheme;

  const setDeviceBrightness = React.useCallback(
    (value: number) => {
      peak?.writeDeviceBrightness(value);
    },
    [peak],
  );

  const resetLantern = React.useCallback(async () => {
    if (!isLanternModeOn) peak?.stopLantern();
    if (partyMode) {
      await peak?.setPartyMode();
    } else if (lanternColorRef.current) {
      await peak?.writeLanternColor(
        lanternColorRef.current,
        lanternPatternRef.current,
      );
    } else if (lanternMoodLight) {
      await peak?.writeLanternMoodLight(lanternMoodLight);
    }
  }, [isLanternModeOn, partyMode, peak, lanternMoodLight]);

  const throttledSetDeviceBrightness = useThrottle(setDeviceBrightness, 300);

  const saveSettings = async (s?: {
    syncUserLanternPreference?: boolean;
    brightness?: number;
    stealth?: boolean;
    lanternMode?: boolean;
    readyMode?: boolean;
  }) => {
    if (!currentDevice || !peak) return;

    if (s?.stealth !== undefined) {
      await peak.writeStealthMode(Number(s.stealth));
    }

    if (s?.lanternMode === true) {
      await peak.startLantern();
    } else if (s?.lanternMode === false) {
      await peak.stopLantern();
    }

    if (s?.readyMode === false) {
      await peak.writeReadyModeOff();
    }

    dispatch(
      updateDeviceSettings({
        id: currentDevice.id,
        syncUserLanternPreference: s?.syncUserLanternPreference ?? true,
        settings: {
          ...(s?.brightness !== undefined && {brightness: s.brightness}),
          stealth: Number(s?.stealth ?? isStealthModeOn),
          lanternMode: s?.lanternMode ?? isLanternModeOn,
          readyMode: s?.readyMode ?? isReadyModeOn,
          // NaN or out of range disables ready mode
          readyProfile:
            s?.readyMode ?? isReadyModeOn
              ? currentDevice?.settings?.readyProfile
              : 10,
        },
      }),
    );
  };

  React.useEffect(() => {
    const titleStyle = {
      ...navMenuTitleStyle,
      ...(isDesert && {color: appColors.white}),
    };
    const iconColor = isDesert ? appColors.white : navMenuIconColor;

    navigation.setOptions({
      headerTitle: 'CONTROL CENTER',
      headerTitleAlign: 'center',
      headerTitleContainerStyle: {},
      headerTitleStyle: titleStyle,
      headerLeft: () => null,
      headerRight: ({disabled, onPress}: HeaderBackButtonProps) => (
        <BackButton
          {...{disabled, onPress}}
          name="close"
          iconStyle={{tintColor: iconColor}}
        />
      ),
    });
  }, [navigation, navMenuTitleStyle, isDesert, navMenuIconColor]);

  React.useEffect(() => {
    lanternPatternRef.current = lanternPattern;
  }, [lanternPattern]);

  React.useEffect(() => {
    lanternColorRef.current = lanternColor;
  }, [lanternColor]);

  useFocusEffect(
    React.useCallback(() => {
      return () => {
        lanternTimer.current && clearTimeout(lanternTimer.current);
        resetLantern();
      };
    }, [resetLantern]),
  );

  const onBegin = () => {
    if (loggedIn && !!lanternMoodLight) {
      peak?.startLantern();

      return;
    }

    peak?.writeLanternColor(
      lanternColor ?? appColors.defaultColor,
      lanternPatternRef.current,
    );

    peak?.startLantern();
  };

  const onEnd = () => {
    saveSettings({brightness: brightnessInterpolate.to(brightness.value)});

    if (lanternTimer.current) clearTimeout(lanternTimer.current);

    lanternTimer.current = setTimeout(() => {
      resetLantern();
    }, 3000);
  };

  return (
    <BlurredBackgroundSafeArea
      style={{backgroundColor: controlCenterTheme.blurBackgroundColor}}>
      <ScreenContainer>
        <DeviceSettingsContainer>
          <LeftSideContainer>
            <FatVerticalSlider
              style={{
                width: COMPONENT_DIAMETER,
                height: CONTROL_CENTER_HEIGHT,
                backgroundColor: fatSliderBGColor,
              }}
              color={fatSliderColor}
              iconSource={light}
              value={brightness}
              onChange={(value: number) => {
                const brightness = brightnessInterpolate.to(value);

                throttledSetDeviceBrightness(brightness);
              }}
              {...{onBegin, onEnd}}
            />
          </LeftSideContainer>

          <ColumnPadding />

          <RightSideContainer>
            <RowContainer>
              <StealthControlContainer>
                <FatButton
                  diameter={COMPONENT_DIAMETER}
                  onPress={() => {
                    navigation.replace(Screens.ControlCenterBoost);
                  }}
                  onIconSource={rocket}
                  iconWidth={30}
                  iconHeight={30}
                />
              </StealthControlContainer>

              <PowerContainer>
                <FatButton
                  diameter={COMPONENT_DIAMETER}
                  onPress={() => {
                    if (!currentDevice) {
                      Alert.alert('Device is currently disconnected');
                      return;
                    }

                    Alert.alert(
                      'Master Off',
                      `Master off will disconnect and power down your device.`,
                      [
                        {text: 'Cancel', style: 'cancel'},
                        {
                          text: 'Turn Off',
                          onPress: () => {
                            preventReconnect();

                            peak?.writeMasterOff();

                            Analytics.shared().logCustomEventAction(
                              CustomEventAction.MASTER_OFF,
                              CustomCategory.CONTROL,
                            );

                            navigation.navigate(Navigators.HomeTabNavigator, {
                              screen: Screens.Home,
                            });
                          },
                        },
                      ],
                      {cancelable: false},
                    );
                  }}
                  onIconSource={power}
                  iconWidth={24}
                  iconHeight={26}
                />
              </PowerContainer>
            </RowContainer>

            <RowContainer>
              <LanternModeContainer>
                <LargeRoundButton
                  height={COMPONENT_DIAMETER}
                  width={BUTTON_WIDTH}
                  {...(!isLedApi2 || !loggedIn
                    ? {
                        onPress: () => {
                          if (isLanternModeOn) {
                            saveSettings({lanternMode: !isLanternModeOn});
                          } else {
                            Alert.alert(
                              Alerts.ACCESS_DENIED,
                              isLedApi2
                                ? Alerts.FEATURE_ACCESS_MESSAGE
                                : Alerts.MOOD_LIGHT_UPDATE_MESSAGE,
                              [
                                {
                                  text: isLedApi2
                                    ? Strings.LOG_IN
                                    : Strings.UPDATE,
                                  onPress: () => {
                                    if (isLedApi2) {
                                      navigation.dispatch(
                                        StackActions.popToTop(),
                                      );
                                      navigation.navigate(Screens.Login, {
                                        redirect: toControlCenter.value,
                                      });
                                    } else {
                                      // Pop to top to prevent errors after update is complete
                                      navigation.dispatch(
                                        StackActions.popToTop(),
                                      );
                                      navigation.navigate(Screens.FirmwareInfo);
                                    }
                                  },
                                  style: 'default',
                                },
                                {text: Strings.CANCEL, style: 'cancel'},
                                {
                                  text: Strings.SKIP,
                                  onPress: () => {
                                    saveSettings({
                                      lanternMode: !isLanternModeOn,
                                    }).then(() => {
                                      navigation.replace(Screens.LanternMode);
                                    });
                                  },
                                },
                              ],
                              {cancelable: false},
                            );
                          }
                        },
                        text: 'LANTERN\nMODE',
                      }
                    : {
                        onPress: () => {
                          if (hasSeenEpilepsyWarning) {
                            navigation.navigate(Screens.MoodLightLibrary);
                            return;
                          }

                          navigation.navigate(Screens.EpilepsyWarning, {
                            redirect: toMoodLightLibrary.value,
                          });
                        },
                        text: 'MOOD\nLIGHTS',
                      })}
                  isActive={
                    !isLedApi2 || !loggedIn
                      ? isLanternModeOn
                      : !!lanternMoodLight
                  }
                  onIconSource={lightBulb}
                  iconWidth={19}
                  iconHeight={30}
                />
              </LanternModeContainer>
            </RowContainer>

            <RowContainer>
              <ReadyModeContainer>
                <LargeRoundButton
                  height={COMPONENT_DIAMETER}
                  width={BUTTON_WIDTH}
                  onPress={() => {
                    if (!isReadyModeOn)
                      return navigation.replace(Screens.SessionReady);

                    saveSettings({readyMode: false});
                  }}
                  isActive={isReadyModeOn}
                  onIconSource={lightningBolt}
                  text={'READY\nMODE'}
                  iconWidth={20}
                  iconHeight={25}
                />
              </ReadyModeContainer>
            </RowContainer>

            <RowContainer>
              <BoostModeContainer>
                <LargeRoundButton
                  height={COMPONENT_DIAMETER}
                  width={BUTTON_WIDTH}
                  onPress={() => {
                    if (!isStealthModeOn) {
                      Analytics.shared().logCustomEventAction(
                        CustomEventAction.STEALTH_ON,
                        CustomCategory.CONTROL,
                      );
                    }

                    saveSettings({
                      syncUserLanternPreference: false,
                      stealth: !isStealthModeOn,
                    });
                  }}
                  isActive={isStealthModeOn}
                  onIconSource={isStealthModeOn ? eyeSlash : eye}
                  text={'STEALTH\nMODE'}
                  iconWidth={32}
                  iconHeight={25}
                />
              </BoostModeContainer>
            </RowContainer>
          </RightSideContainer>
        </DeviceSettingsContainer>
      </ScreenContainer>
    </BlurredBackgroundSafeArea>
  );
};

const ScreenContainer = styled(View)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  flex: 1,
});

const DeviceSettingsContainer = styled(View)({
  display: 'flex',
  flexDirection: 'row',
  width: CONTROL_CENTER_WIDTH,
  height: CONTROL_CENTER_HEIGHT,
  alignSelf: 'center',
  justifyContent: 'center',
});

const LeftSideContainer = styled(View)({
  display: 'flex',
  width: COMPONENT_DIAMETER,
});

const ColumnPadding = styled(View)({
  height: '100%',
  width: 20,
});

const RightSideContainer = styled(View)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: BUTTON_WIDTH,
});

const RowContainer = styled(View)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: BUTTON_WIDTH,
  height: COMPONENT_DIAMETER,
});

const StealthControlContainer = styled(View)({
  display: 'flex',
});

const PowerContainer = styled(View)({
  display: 'flex',
});

const LanternModeContainer = styled(View)({
  display: 'flex',
});

const ReadyModeContainer = styled(View)({
  display: 'flex',
});

const BoostModeContainer = styled(View)({
  display: 'flex',
});
