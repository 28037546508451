import React from 'react';
import {StyleProp, ViewStyle} from 'react-native';

import {Gallery, GalleryViewerModal} from '.';
import {Modal} from '../../../../components';
import {resolveAssetUrl} from '../../../../lib/api';
import {
  TypeStyledAsset,
  isTypeGallery,
  isTypeStyledAsset,
  isTypeVideo,
} from '../../../../lib/api/content-access/types';
import {GalleryItem} from '../../../../lib/api/content-access/types/TypeGallery';
import {AssetCarouselItem} from './AssetCarouselItem';

interface Props {
  item: GalleryItem;
  index: number;
  all?: GalleryItem[];
  style?: StyleProp<ViewStyle>;
}

export const CarouselItem = ({item, all, index}: Props) => {
  if (isTypeVideo(item)) {
    const video = item;
    const thumbnailUrl = resolveAssetUrl(video.fields.thumbnailImage);

    return (
      <AssetCarouselItem
        thumbnail={thumbnailUrl}
        link={video.fields.link}
        title={item.fields.title}
      />
    );
  }

  if (isTypeStyledAsset(item)) {
    if (!all) return null;

    const asset = item;
    const thumbnailUrl = resolveAssetUrl(asset.fields.asset);

    return (
      <AssetCarouselItem
        thumbnail={thumbnailUrl}
        title={item.fields.title}
        thumbnailStyle={item.fields.style}
        onPress={() =>
          Modal.display({
            element: (
              <GalleryViewerModal
                index={index}
                assets={all as TypeStyledAsset[]}
                onClose={() => Modal.close()}
                showTitle
              />
            ),
          })
        }
      />
    );
  }

  if (isTypeGallery(item)) {
    const gallery = item;

    return <Gallery {...{gallery}} />;
  }

  return null;
};
