import {BLOCKS, Block, Inline} from '@contentful/rich-text-types';
import {MaterialTopTabScreenProps} from '@react-navigation/material-top-tabs';
import {ParamListBase} from '@react-navigation/routers';
import React from 'react';
import {ScrollView, StyleSheet, Text, View} from 'react-native';

import {ErrorContainer, HeroImage} from '.';
import {BackToTopButton} from '../../../components/BackToTopButton';
import {appColors} from '../../../constants';
import {resolveAssetUrl} from '../../../lib/api';
import {useAdaptiveSafeArea} from '../../../lib/hooks/useAdaptiveSafeArea';
import {useLoadPage} from '../../../lib/hooks/useLoadPages';
import {LoadingContainer} from '../../Education/components/LoadingContainer';
import {
  RichTextRenderer,
  isEmptyParagraph,
} from '../../Education/components/RichTextRenderer';

interface ScreenProps extends MaterialTopTabScreenProps<ParamListBase> {}

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node: Block | Inline, children: React.ReactNode) => {
      if (isEmptyParagraph(node)) return <View style={styles.emptyParagraph} />;

      return <Text style={styles.text}>{children}</Text>;
    },
  },
};

export const Page: React.FC<ScreenProps> = ({route}) => {
  const screenName = route.name;

  const {page, loading} = useLoadPage({screenName});

  useAdaptiveSafeArea();

  const scrollViewRef = React.useRef<ScrollView>(null);

  if (loading) return <LoadingContainer />;

  if (!page) return <ErrorContainer />;

  const fields = page.fields;

  return (
    <View style={styles.layout}>
      <ScrollView
        ref={scrollViewRef}
        showsVerticalScrollIndicator={false}
        style={styles.scroller}>
        {fields.heroImage && (
          <HeroImage
            source={{
              uri: resolveAssetUrl(fields.heroImage),
            }}
          />
        )}

        <View style={styles.content}>
          <RichTextRenderer content={fields?.content} customOptions={options} />

          {!!fields.backToTopButton && (
            <BackToTopButton
              onPress={() =>
                scrollViewRef.current?.scrollTo({y: 0, animated: true})
              }
            />
          )}
        </View>
      </ScrollView>

      {!!fields.fixedFooter && (
        <View style={styles.footer}>
          <RichTextRenderer content={fields.fixedFooter} />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  layout: {
    paddingTop: 16,
    flex: 1,
    flexBasis: 0,
    backgroundColor: appColors.black,
  },
  scroller: {
    flex: 1,
  },
  content: {
    marginTop: 48,
    paddingHorizontal: 24,
    paddingBottom: 124,
  },
  listItemContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: 10,
  },
  listItemText: {
    fontSize: 16,
    fontFamily: 'Roboto-Regular',
    fontWeight: '400',
    alignSelf: 'flex-start',
    color: appColors.textOnSurface,
    lineHeight: 28,
  },
  bulletPoint: {
    lineHeight: 24,
  },

  text: {
    fontSize: 16,
    fontFamily: 'Roboto-Regular',
    fontWeight: '400',
    alignSelf: 'flex-start',
    color: appColors.textOnSurface,
    lineHeight: 26,
  },
  ulList: {
    paddingLeft: 8,
    paddingTop: 12,
  },
  emptyParagraph: {
    height: 16,
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    paddingHorizontal: 24,
    paddingTop: 28,
    paddingBottom: 28,
    backgroundColor: appColors.black,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
