import React from 'react';
import {Image, Linking, StyleSheet, Text, View} from 'react-native';

import {StyledButton} from '../../../components';
import {Strings, appColors} from '../../../constants';
import {resolveAssetUrl} from '../../../lib/api';
import {TypeLinkItem} from '../../../lib/api/content-access/types';

interface Props {
  item: TypeLinkItem;
}

export const LinkItem: React.FC<Props> = ({item}) => {
  return (
    <View
      style={[
        styles.root,
        item.fields.separator
          ? {
              borderBottomWidth: 1,
              borderBottomColor: appColors.darkGreyMedium33,
            }
          : {},
      ]}>
      <View style={styles.textAndIcon}>
        {item.fields.icon && (
          <Image
            style={styles.icon}
            source={{
              uri: resolveAssetUrl(item.fields.icon),
            }}
          />
        )}

        <View style={styles.text}>
          <Text
            style={[
              styles.displayName,
              item.fields.description ? styles.bold : {},
            ]}>
            {item.fields.displayName}
          </Text>

          {item.fields.description && (
            <Text style={styles.description}>{item.fields.description}</Text>
          )}
        </View>
      </View>

      {item.fields.url && (
        <StyledButton
          style={styles.button}
          textStyle={styles.bold}
          title={item.fields.buttonText ?? Strings.OPEN}
          onPress={() => {
            Linking.openURL(item.fields.url);
          }}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    paddingVertical: 24,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textAndIcon: {display: 'flex', flexDirection: 'row', alignItems: 'center'},
  icon: {
    width: 42,
    height: 42,
    marginRight: 14,
    resizeMode: 'contain',
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  bold: {fontFamily: 'Roboto-Bold'},
  displayName: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    color: appColors.white,
  },
  description: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    color: appColors.textOnSurface,
    marginTop: 12,
  },
  button: {
    width: 'auto',
    height: 'auto',
    paddingVertical: 12,
    paddingHorizontal: 24,
  },
});
