import React from 'react';
import {Image, StyleSheet, Text, View} from 'react-native';
import {PanGestureHandler} from 'react-native-gesture-handler';

import {CloseButton, StyledButton} from '../../../../components';
import {Constants, Strings, appColors} from '../../../../constants';
import {resolveAssetUrl} from '../../../../lib/api';
import {TypeStyledAsset} from '../../../../lib/api/content-access/types';
import {useSwipe} from '../../../../lib/hooks';
import {addOpacityToColorHex} from '../../../../lib/utilityFunctions';

interface GalleryViewerModalProps {
  onClose: () => void;
  assets: TypeStyledAsset[];
  index?: number;
  showTitle?: boolean;
}

export const GalleryViewerModal: React.FC<GalleryViewerModalProps> = ({
  onClose,
  assets,
  index = 0,
  showTitle = false,
}) => {
  const [currentIndex, setCurrentIndex] = React.useState(index);

  const nextImage = () => {
    if (currentIndex === assets.length - 1) return;

    setCurrentIndex(prevIndex => (prevIndex + 1) % assets.length);
  };

  const previousImage = () => {
    if (currentIndex === 0) return;

    setCurrentIndex(
      prevIndex => (prevIndex - 1 + assets.length) % assets.length,
    );
  };

  const onEnded = useSwipe({
    onLeft: nextImage,
    onRight: previousImage,
  });

  const currentImageUrl = React.useMemo(
    () => resolveAssetUrl(assets[currentIndex].fields.asset),
    [assets, currentIndex],
  );

  return (
    <View style={styles.modalContainer}>
      <View style={styles.header}>
        <Text style={styles.count}>
          {currentIndex + 1} / {assets.length}
        </Text>

        {showTitle && (
          <Text numberOfLines={1} style={styles.title}>
            {assets[currentIndex].fields.title}
          </Text>
        )}

        <CloseButton
          size={20}
          containerSize={25}
          color={appColors.white}
          onPress={onClose}
        />
      </View>

      <PanGestureHandler {...{onEnded}} minDist={25}>
        <View style={[styles.imageContainer]}>
          <Image source={{uri: currentImageUrl}} style={styles.image} />
        </View>
      </PanGestureHandler>

      <View style={styles.footer}>
        {currentIndex > 0 && (
          <StyledButton
            title={Strings.PREVIOUS}
            style={styles.button}
            theme={{
              buttonColor: addOpacityToColorHex(appColors.white, 0.2),
              buttonTextColor: addOpacityToColorHex(appColors.white, 0.5),
            }}
            onPress={previousImage}
          />
        )}

        {currentIndex < assets.length - 1 && (
          <StyledButton
            style={styles.button}
            title={Strings.NEXT}
            onPress={nextImage}
          />
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    width: '100%',
    flex: 1,
    paddingTop: 64,
    backgroundColor: appColors.black,
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '80%',
    alignItems: 'center',
    marginBottom: 24,
  },
  image: {
    aspectRatio: 0.667,
    resizeMode: 'contain',
    maxHeight: '100%',
    marginHorizontal: Constants.IS_NATIVE_ANDROID ? 'auto' : undefined,
  },
  imageContainer: {
    flex: 1,
    width: '100%',
  },
  footer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 12,
    marginTop: 20,
    paddingHorizontal: 28,
    marginBottom: 40,
  },
  button: {
    flex: 1,
  },
  count: {
    color: addOpacityToColorHex(appColors.white, 0.5),
    fontSize: 14,
  },
  title: {
    color: appColors.white,
    fontSize: Constants.DIMENSIONS.WIDTH < 400 ? 14 : 16,
    textTransform: 'uppercase',
    fontFamily: 'Roboto-Bold',
  },
});
