import {Asset, Entry, Link} from 'contentful';

export const isResolvedAssetLink = (
  assetLink: Link<'ContentType'> | Asset | Entry<any>,
): assetLink is Asset =>
  !!(assetLink as Asset).fields && !!(assetLink as Asset).fields.file;

export const isResolvedEntryLink = <T>(
  entryLink: Entry<T> | Link<'ContentType'>,
): entryLink is Entry<T> => !!(entryLink as Entry<T>).fields;

const asHttps = (link: string) => `https:${link}`;

export const resolveAssetUrl = (asset: Link<'ContentType'> | Asset) => {
  if (!isResolvedAssetLink(asset)) throw new Error('Asset link unresolved');

  if (!asset.fields.file) throw new Error('Missing asset file');

  return asHttps(asset.fields.file.url);
};
