import React from 'react';
import {StyleProp, StyleSheet, Text, View, ViewStyle} from 'react-native';

import {appColors} from '../../../constants';

interface Props {
  title?: string;
  style?: StyleProp<ViewStyle>;
}

export const Section = ({
  title,
  style,
  children,
}: React.PropsWithChildren<Props>) => (
  <View style={[styles.container, style]}>
    {!!title && <Text style={styles.title}>{title}</Text>}

    {children}
  </View>
);

const styles = StyleSheet.create({
  container: {
    marginBottom: 48,
  },
  title: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    textTransform: 'uppercase',
    fontWeight: '400',
    color: appColors.white,
    opacity: 0.5,
    marginBottom: 16,
  },
});
