import {NavigationProp, useNavigation} from '@react-navigation/core';
import React from 'react';

import {DefaultGallery} from '.';
import {SupportItemSection} from '..';
import {Strings} from '../../../../constants';
import {GalleryType} from '../../../../lib/api';
import {TypeGallery, TypePage} from '../../../../lib/api/content-access/types';
import {Carousel} from './Carousel';

interface Props {
  gallery: TypeGallery;
}

interface Navigation extends NavigationProp<Record<string, undefined>> {}

export const Gallery = ({gallery}: Props) => {
  const navigation = useNavigation<Navigation>();

  const items = gallery.fields.elements;

  if (!items) return null;

  if (gallery.fields.type === GalleryType.CAROUSEL)
    return <Carousel {...{items}} title={gallery.fields.displayName} />;

  if (gallery.fields.type === GalleryType.DEFAULT) {
    return <DefaultGallery {...{gallery}} />;
  }

  if (gallery.fields.type === GalleryType.SUPPORT_ITEM) {
    const pages = gallery.fields.elements as TypePage[];

    return (
      <SupportItemSection
        title={gallery.fields.displayName ?? Strings.ADDITIONAL_INFORMATION}
        items={[
          ...(pages ?? []).map(page => {
            return {
              title: page.fields.title,
              onPress: () => {
                navigation.navigate(page.fields.screenName);
              },
            };
          }),
        ]}
      />
    );
  }

  // TODO: Handle the other gallery types
  return null;
};
