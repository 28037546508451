import type {Asset, Entry, EntryFields} from 'contentful';

import {TypePageFields, TypeStyledAssetFields, TypeVideoFields} from '.';
import type {WithContentTypeLink} from './WithContentTypeLink';

export interface GalleryItem
  extends Entry<
    TypeStyledAssetFields | TypeVideoFields | TypeGalleryFields | TypePageFields
  > {}

export interface TypeGalleryFields {
  title: EntryFields.Symbol;
  elements?: GalleryItem[];
  type: 'CAROUSEL' | 'CONTEST' | 'DEFAULT' | 'GRID' | 'SUPPORT_ITEM';
  displayName?: EntryFields.Symbol;
  thumbnailImage?: Asset;
}

export type TypeGallery = Entry<TypeGalleryFields>;

export function isTypeGallery(
  entry: WithContentTypeLink,
): entry is TypeGallery {
  return entry?.sys?.contentType?.sys?.id === 'gallery';
}
