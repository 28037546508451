import Constants from 'expo-constants';
import debounce from 'lodash/debounce';
import {ConnectedDevice} from 'pikaparam';
import {InteractionManager, Platform} from 'react-native';
import {
  configLoggerType,
  consoleTransport,
  logger,
  transportFunctionType,
} from 'react-native-logs';

import {Constants as C, Environment} from '../constants';
import {appVersion} from '../shims/AppVersion';
import {
  captureError,
  configureScope,
  setDevice as setSentryDevice,
} from '../shims/sentry';
import {appLogsApi} from './api/apis';
import {OtaDevice} from './ble2/v2/OtaDevice';
import {IPeakDevice} from './ble2/v2/PeakDevice/IPeakDevice';
import {User} from './types';
import {serializeDevice} from './utilityFunctions/serializeDevice';

enum LogNamespace {
  App = 'app',
  Bluetooth = 'ble',
  Ota = 'ota',
  Device = 'device',
}

let user: User | undefined;
let device: ConnectedDevice | IPeakDevice | OtaDevice | undefined;

type LoggingProps = Parameters<transportFunctionType>[0];

const serializeError = (error: Error) => {
  return JSON.parse(JSON.stringify(error, Object.getOwnPropertyNames(error)));
};

const parseLoggingProps = (props: LoggingProps) => {
  // The first arg is the message
  const message = props.rawMsg[0];

  // The rest of the args are the params and can be merged together into the payload
  const payload: any = (props.rawMsg.slice(1) as object[]).reduce(
    (obj, data: any) => {
      if (typeof data !== 'object') return obj;

      return {...obj, ...data};
    },
    {} as Record<string, any>,
  );

  return {
    message,
    payload,
    category: props.extension,
    device: device && serializeDevice(device),
  };
};

const sentryTransport: transportFunctionType = props => {
  if (props.level.text !== 'error') return;

  const {message, payload, category, device} = parseLoggingProps(props);

  const error = !payload.error
    ? undefined
    : payload.error instanceof Error
      ? (payload.error as Error)
      : typeof payload.error === 'string'
        ? new Error(payload.error)
        : typeof payload.error === 'object' && payload.error.message
          ? new Error(payload.error.message)
          : undefined;

  if (!error) return;

  error.name = error.name === 'Error' ? message : error.name;

  configureScope(scope => {
    if (category) scope.setTag('log.category', category);

    if (device) setSentryDevice(scope, device);

    captureError(error);
  });
};

const customConsoleTransport: transportFunctionType = props => {
  const {payload} = parseLoggingProps(props);

  if (payload.error && typeof payload.error === 'object')
    props.msg = props.msg.replace(
      `"error": {}`,
      `"error": ${JSON.stringify(payload.error.message)}`,
    );

  consoleTransport(props);
};

const remoteLoggingTransport: transportFunctionType = (() => {
  const logs: {date: string}[] = [];

  const debouncedSend = debounce(
    async () => {
      const length = logs.length;

      if (!length) return;

      return appLogsApi
        .createAppLog({appLogsCreateDto: {logs}})
        .then(() => {
          // during the sync other logs may have arrived so we have to keep them
          logs.splice(0, length);
        })
        .catch(() => void 0);
    },
    1000,
    {maxWait: 5000},
  );

  return props => {
    // Safety check because we clean up the logs array after they could be synced
    // If an intermitent errors occur during sync, we do not want to grow this list indefinitely
    if (logs.length > 10000) return;

    const {message, payload, device} = parseLoggingProps(props);

    if (payload.error instanceof Error)
      payload.error = serializeError(payload.error);

    const entry = {
      date: new Date().toISOString(),
      level: props.level.text,
      extension: props.extension,
      message,
      platform: {
        os: Platform.OS,
        version: Platform.Version,
        constants: Platform.constants,
      },
      app: {environment: Environment.environment, version: appVersion},
      sessionId: Constants.sessionId,
      userId: user?.id,
      ...(device && {device}),
      payload,
    };

    logs.push(entry);

    debouncedSend();
  };
})();

const config: configLoggerType = {
  severity: 'info',
  transport: [customConsoleTransport, sentryTransport, remoteLoggingTransport],
  transportOptions:
    __DEV__ && !C.IS_USING_PATH_BROWSER
      ? {
          colors: {
            info: 'blueBright',
            warn: 'yellowBright',
            error: 'redBright',
          },
        }
      : undefined,
  async: true,
  asyncFunc: InteractionManager.runAfterInteractions,
  dateFormat: 'iso',
  printLevel: true,
  printDate: true,
  enabled: true,
  enabledExtensions: Object.values(LogNamespace),
};

// default logger with no namespace
const log = logger.createLogger(config);

// namespace loggers
export const appLog = log.extend(LogNamespace.App);
export const bleLog = log.extend(LogNamespace.Bluetooth);
export const otaLog = log.extend(LogNamespace.Ota);
export const deviceLog = log.extend(LogNamespace.Device);

export const setUser = (u?: User) => {
  user = u;
};

export const setDevice = (d?: IPeakDevice | OtaDevice | ConnectedDevice) => {
  device = d;
};
