import {NavigationProp, PartialRoute} from '@react-navigation/native';

import {Navigators, Screens} from '../../constants';
import {NavigationAction} from '../../src/services/settings/BaseSettingsService';
import {RootStackParamList, RootStackScreenProps} from './RootStackNavigator';
import {RedirectOptions, RedirectionParameter} from './params';

export const isNavigatingTo = (
  screen: string,
  args: RedirectOptions,
): boolean => {
  if (args[0] === screen) return true;

  const options = args[1];

  // @ts-ignore
  if (!options || !options.screen) return false;

  // @ts-ignore
  return isNavigatingTo(screen, [options.screen, options.params]);
};

export const toHome: RedirectOptions = [
  Navigators.MainNavigator,
  {
    screen: Navigators.HomeDrawerNavigator,
    params: {
      screen: Navigators.HomeEmulatedDrawer,
      params: {
        screen: Navigators.HomeTabNavigator,
        params: {
          screen: Screens.Home,
        },
      },
    },
  },
];

export const toHeatProfileSelect: RedirectOptions = [
  Navigators.MainNavigator,
  {
    screen: Navigators.HomeDrawerNavigator,
    params: {
      screen: Navigators.HomeEmulatedDrawer,
      params: {
        screen: Screens.HeatProfileSelect,
      },
    },
  },
];

export const toHomeRedirect = new RedirectionParameter(...toHome);

export const toDevicesList: RedirectOptions = [
  Navigators.MainNavigator,
  {
    screen: Navigators.HomeDrawerNavigator,
    params: {
      screen: Navigators.HomeEmulatedDrawer,
      params: {
        screen: Navigators.DevicesList,
        params: {
          screen: Screens.DevicesList,
        },
      },
    },
  },
];

export const toControlCenter = new RedirectionParameter(
  Navigators.MainNavigator,
  {
    screen: Navigators.HomeDrawerNavigator,
    params: {
      screen: Navigators.HomeEmulatedDrawer,
      params: {
        screen: Navigators.ControlCenter,
        params: {screen: Screens.ControlCenter},
      },
    },
  },
);

export const toAdvancedMetrics = new RedirectionParameter(
  Navigators.MainNavigator,
  {
    screen: Navigators.HomeDrawerNavigator,
    params: {
      screen: Navigators.HomeEmulatedDrawer,
      params: {
        screen: Screens.AdvancedMetrics,
      },
    },
  },
);

export const toMoodLightLibrary = new RedirectionParameter(
  Navigators.MainNavigator,
  {
    screen: Navigators.HomeDrawerNavigator,
    params: {
      screen: Navigators.HomeEmulatedDrawer,
      params: {
        screen: Navigators.ControlCenter,
        params: {
          screen: Screens.MoodLightLibrary,
        },
      },
    },
  },
);

export const toEpilepsyWarning = (redirect: RedirectOptions) =>
  new RedirectionParameter(Navigators.MainNavigator, {
    screen: Navigators.HomeDrawerNavigator,
    params: {
      screen: Navigators.HomeEmulatedDrawer,
      params: {
        screen: Navigators.ControlCenter,
        params: {
          screen: Screens.EpilepsyWarning,
          params: {redirect},
        },
      },
    },
  });

export const toHeatProfileEdit = (o: {profileId?: string}) =>
  new RedirectionParameter(Navigators.HeatProfileEdit, {
    screen: Screens.HeatProfileEdit,
    params: o,
  });

export const toShareMoodlight = (shareId: string) =>
  new RedirectionParameter(Navigators.MainNavigator, {
    screen: Navigators.HomeDrawerNavigator,
    params: {
      screen: Navigators.HomeEmulatedDrawer,
      params: {
        screen: Screens.SharedMood,
        params: {shareId},
      },
    },
  });

export const toShareProfile = ({
  shareId,
  save,
}: {
  shareId: string;
  save?: boolean;
}) =>
  new RedirectionParameter(Navigators.MainNavigator, {
    screen: Navigators.HomeDrawerNavigator,
    params: {
      screen: Navigators.HomeEmulatedDrawer,
      params: {
        screen: Navigators.SharedHeatProfile,
        params: {
          screen: Screens.SharedHeatProfile,
          params: {shareId, save},
        },
      },
    },
  });

export const toStartPairing = ({redirect}: {redirect?: RedirectOptions} = {}) =>
  new RedirectionParameter(Navigators.MainNavigator, {
    screen: Screens.BluetoothStartPairing,
    params: {redirect},
  }).value;

export const optionsToRoute = ([name, params]: RedirectOptions): PartialRoute<
  RootStackScreenProps<keyof RootStackParamList>['route']
> => {
  return {name, params};
};

export const routeToOptions = (
  route: RootStackScreenProps<keyof RootStackParamList>['route'],
): RedirectOptions => {
  return [route.name as any, route.params ?? {}];
};

export const getUrlFromOptions = ([name, params]: RedirectOptions): string => {
  if (!params) return '/' + name;

  // @ts-expect-error
  if (!params?.screen) {
    const qs = Object.entries(params)
      .filter(([, value]) => value !== undefined)
      .map(
        ([key, value]) =>
          `${key}=${key === 'redirect' ? getUrlFromOptions(value) : value}`,
      )
      .join('&');

    return '/' + name + (qs.length > 0 ? `?${qs}` : '');
  }

  return (
    // @ts-expect-error
    '/' + [name, getUrlFromOptions([params.screen, params.params])].join('')
  );
};

export const getOptionsFromAction = (
  action: NavigationAction,
): RedirectOptions => {
  return [action.payload.name as any, action.payload.params];
};

export const getNavigationRoute = (
  nav: NavigationProp<any>,
): RootStackScreenProps<keyof RootStackParamList>['route'] => {
  const parent = nav.getParent();

  if (parent) return getNavigationRoute(parent);

  const state = nav.getState();

  return state.routes[state.routes.length - 1] as any;
};

export const toHeatProfileList = new RedirectionParameter(
  Navigators.MainNavigator,
  {
    screen: Navigators.HomeDrawerNavigator,
    params: {
      screen: Navigators.HomeEmulatedDrawer,
      params: {
        screen: Navigators.HomeTabNavigator,
        params: {
          screen: Screens.HeatProfileList,
          params: {},
        },
      },
    },
  },
);
