import {ConnectedDevice, Pikaparam, Uuids} from 'pikaparam';
import {
  distinctUntilChanged,
  filter,
  firstValueFrom,
  throwError,
  timeout,
} from 'rxjs';

import {
  bleConnectDisconnectErrorEvent,
  bleConnectUnknownErrorEvent,
} from '../../../../analytics/BleConnect';
import {bleLog, setDevice as setLoggerDevice} from '../../../Logger';
import {sleep} from '../../../sleep';
import {linearInterpolate} from '../../../utilityFunctions';
import {OtaDevice} from '../OtaDevice';
import {IPeakDevice} from '../PeakDevice/IPeakDevice';
import {PeakDeviceFactory} from '../PeakDeviceFactory';
import {Logger} from '../pikaparam';
import {ConnectionError, Progress} from '../types';

export type FirmwareUpdatePhase =
  | 'not_started'
  | 'checking_device'
  | 'updating'
  | 'done'
  | 'rebooting_to_apploader'
  | 'reconnect';

export type ConnectionPhase =
  | 'scanning'
  | 'retrieve_services'
  | 'bonding'
  | 'requesting_mtu'
  | 'connecting'
  | 'waiting_for_ota'
  | 'waiting_for_peak'
  | 'setting_up'
  | 'initializing'
  | 'reading'
  | 'done';

export interface UpdateFirmwareOptions {
  name?: string;
  firmware: Buffer;
  onProgress(progress: Progress<FirmwareUpdatePhase>): void;
}

export interface OtaConnectOptions {
  name?: string;
}

export type OnDisconnect = () => Promise<void>;
export type OnConnectProgress = (
  progress: Partial<Progress<ConnectionPhase>>,
) => void;

export interface ConnectOptions {
  timeout?: number;
  onDisconnect?: OnDisconnect;
  onProgress?: OnConnectProgress;
}

export interface ScanOptions {
  timeout?: number;
}

export interface IBleManager<D> {
  peak?: IPeakDevice;
  otaDevice?: OtaDevice;

  isConnected(peripheralId: string): Promise<boolean>;

  scanForDevice(o: ScanOptions): Promise<D>;

  connectToDevice(device: D, o: ConnectOptions): Promise<void>;
  connect(
    peripheralId: string,
    name: string | undefined,
    o: ConnectOptions,
  ): Promise<void>;

  updateFirmware(options: UpdateFirmwareOptions): Promise<void>;
  otaConnect(o: OtaConnectOptions): Promise<OtaDevice>;

  scanForAdvertisements(
    callback: (
      device: any,
      advertisementData: Record<string | number, any> | any,
    ) => void,
  ): Promise<void>;

  stopScanForAdvertisements(): Promise<void>;
}

export abstract class BleManagerBase<D, CD extends ConnectedDevice> {
  protected static readonly SCAN_TIMEOUT = 10000;

  protected serviceUuids = [
    Uuids.loraxService,
    Uuids.pikachuService,
    Uuids.pupService,
    Uuids.silabsOtaService,
  ];

  private _peak: IPeakDevice | undefined;
  private _ota: OtaDevice | undefined;

  protected logger: Logger = {
    debug: (...data: any[]) => bleLog.info(...data),
    info: (...data: any[]) => bleLog.info(...data),
    log: (...data: any[]) => bleLog.info(...data),
    warn: (...data: any[]) => bleLog.warn(...data),
    error: (...data: any[]) => bleLog.error(...data),
  };

  protected abstract createConnectedDevice(device: D): Promise<CD>;

  protected abstract bond(device: CD): Promise<void>;
  protected abstract requestMtu(device: CD): Promise<void>;

  public abstract isConnected(peripheralId: string): Promise<boolean>;

  public abstract otaConnect(
    o: OtaConnectOptions,
  ): Promise<OtaDevice | undefined>;

  public abstract scanForDevice(o: ScanOptions): Promise<D>;

  public abstract connect(
    peripheralId: string,
    name: string | undefined,
    o: ConnectOptions,
  ): Promise<void>;
  public abstract connectToDevice(device: D, o: ConnectOptions): Promise<void>;

  protected abstract stopScan(): Promise<void>;

  public abstract scanForAdvertisements(
    callback: (
      device: any,
      advertisementData: Record<string | number, any> | any,
    ) => void,
  ): Promise<void>;

  public abstract stopScanForAdvertisements(): Promise<void>;

  public get peak() {
    return this._peak;
  }

  private set peak(peak: IPeakDevice | undefined) {
    this._peak = peak;
    setLoggerDevice(peak);
  }

  public get otaDevice() {
    return this._ota;
  }

  private set otaDevice(ota: OtaDevice | undefined) {
    this._ota = ota;
    setLoggerDevice(ota);
  }

  private async ensureOtaInBootloader(
    {name}: Pick<UpdateFirmwareOptions, 'name'>,
    attempt = 1,
  ): Promise<OtaDevice> {
    const otaDevice = await this.getOrConnectOtaDevice({name});

    if (!otaDevice) return this.ensureOtaInBootloader({name}, attempt + 1);

    if (otaDevice.isInBootloaderMode()) return otaDevice;

    if (attempt >= 3)
      throw new Error(
        `Device couldn't enter bootloader state after ${attempt} attempts.`,
      );

    const newDeviceName = await otaDevice.rebootToBootloader();

    return this.ensureOtaInBootloader({name: newDeviceName}, attempt + 1);
  }

  public async updateFirmware({
    name,
    firmware,
    onProgress,
  }: UpdateFirmwareOptions) {
    bleLog.info('Update firmware.', {name});

    onProgress({
      value: 0.2,
      data: 'rebooting_to_apploader',
      duration: 5000,
    });

    const otaDevice = await this.ensureOtaInBootloader({name});

    await firstValueFrom(
      otaDevice.flashStateObs.pipe(
        filter(v => v === 'idle'),
        timeout({
          each: 5000,
          with: () =>
            throwError(
              () =>
                new Error(
                  "Ota device flash state didn't become idle in 5 seconds.",
                ),
            ),
        }),
      ),
    );

    onProgress({value: 0.2, data: 'updating', duration: 2000});

    const progressSubscription = otaDevice.subscribeToFlashProgress(
      progress => {
        const value = linearInterpolate(
          progress,
          {min: 0, max: 100},
          {min: 0.2, max: 0.8},
        );

        onProgress({value, data: 'updating', duration: 500});
      },
    );

    bleLog.info('Flashing device...');

    await otaDevice
      .flash(firmware.buffer)
      .finally(() => progressSubscription.unsubscribe());

    onProgress({value: 0.9, data: 'updating', duration: 1000});

    await firstValueFrom(
      otaDevice.flashStateObs.pipe(
        filter(v => ['finish', 'idle'].includes(v)),
        timeout({
          each: 5000,
          with: () =>
            throwError(
              () =>
                new Error(
                  "Ota device flash state didn't become finish or idle in 5 seconds.",
                ),
            ),
        }),
      ),
    );

    await otaDevice.disconnect();

    onProgress({value: 1, data: 'updating', duration: 10000});

    // We need this sleep to wait for the peak to reboot so we can reconnect to it
    await sleep(10000);
  }

  protected async connectWithPikaparam(
    _device: D,
    {onProgress, onDisconnect}: ConnectOptions = {},
  ) {
    const now = Date.now();

    onProgress?.({value: 0.3, data: 'retrieve_services', duration: 1000});

    const device = await this.createConnectedDevice(_device);

    try {
      setLoggerDevice(device);

      const pikaparam = new Pikaparam(this.logger);

      const serviceUuid = Uuids.silabsOtaService;
      const characteristicUuid = Uuids.silabsOtaLoaderVersionChar;

      const bootloader = device.hasChar(serviceUuid, characteristicUuid);

      if (!bootloader) {
        onProgress?.({value: 0.4, data: 'bonding', duration: 1000});
        await this.bond(device);
      } else {
        bleLog.info('Device is in bootloader state. Bonding is skipped.', {
          peripheralId: device.id,
        });
      }

      onProgress?.({value: 0.5, data: 'requesting_mtu', duration: 1000});

      await this.requestMtu(device);

      onProgress?.({value: 0.6, data: 'connecting', duration: 500});

      await pikaparam.conn.connect(device);

      onProgress?.({value: 0.7, data: 'waiting_for_ota', duration: 2000});

      await firstValueFrom(
        pikaparam.ota.deviceStateObs.pipe(
          filter(v => v !== 'offline'),
          timeout({
            each: 5000,
            with: () =>
              throwError(
                () =>
                  new Error("Ota device didn't become available in 5 seconds."),
              ),
          }),
        ),
      );

      this.otaDevice = new OtaDevice({device, pikaparam});

      bleLog.info('Ota device connected.', {
        peripheralId: this.otaDevice.peripheralId,
        deviceState: this.otaDevice.deviceState,
        flashState: this.otaDevice.flashState,
        duration: Date.now() - now,
      });

      if (this.otaDevice.isInBootloaderMode()) {
        bleLog.info('Ota device is in bootloader state. Peak is not created.', {
          peripheralId: this.otaDevice.peripheralId,
        });
        return {ota: this.otaDevice};
      }

      pikaparam.conn.deviceStateObs
        .pipe(
          filter(v => v === 'disconnected'),
          distinctUntilChanged(),
        )
        .subscribe(async () => {
          await onDisconnect?.();

          this.otaDevice = undefined;

          const peak = this.peak;

          if (!peak?.connected) return;

          bleConnectDisconnectErrorEvent(peak.firmwareType, peak.modelNumber);

          this.peak = undefined;
        });

      onProgress?.({value: 0.8, data: 'waiting_for_peak', duration: 2000});

      await firstValueFrom(
        pikaparam.param.isAvailableObs.pipe(
          filter(v => !!v),
          timeout({
            each: 5000,
            with: () =>
              throwError(
                () =>
                  new Error(
                    "Peak device didn't become available in 5 seconds.",
                  ),
              ),
          }),
        ),
      );

      onProgress?.({value: 0.85, data: 'setting_up', duration: 1000});

      const peak = await PeakDeviceFactory.create({device, pikaparam});

      this.peak = peak;

      onProgress?.({value: 0.9, data: 'initializing', duration: 4000});

      try {
        await peak.initialize();
      } catch (error) {
        bleLog.error(`Couldn't connect to peak.`, {error});
        bleConnectUnknownErrorEvent(peak.firmwareType, error);
        throw new Error(ConnectionError.CONNECTION_ERROR);
      }

      bleLog.info('Peak connected.', {
        id: peak.peripheralId,
        name: peak.name,
        duration: Date.now() - now,
      });

      onProgress?.({value: 1, duration: 2000, data: 'reading'});

      return {ota: this.otaDevice, peak};
    } catch (error) {
      await device.startDisconnect().catch(() => void 0);
      throw error;
    }
  }

  private async getOrConnectOtaDevice({name}: OtaConnectOptions) {
    if (!this.otaDevice) {
      bleLog.info('Ota device is not connected. Connecting.', {name});
      return await this.otaConnect({name});
    }

    if (this.otaDevice.deviceState === 'offline') {
      bleLog.info('Ota device is currently offline. Connecting.', {
        name,
        connectedDeviceId: this.otaDevice.peripheralId,
      });
      return await this.otaConnect({name});
    }

    bleLog.info('Ota device is already connected. Skipping connection.', {
      name,
      deviceState: this.otaDevice.deviceState,
    });

    return this.otaDevice;
  }
}
