import React from 'react';
import {useSelector} from 'react-redux';

import {Constants, Navigators, Screens} from '../constants';
import {appLog} from '../lib/Logger';
import NavigationService from '../lib/NavigationService';
import {bleManager} from '../lib/ble2/v2/BleManager';
import {ConnectionPhase} from '../lib/ble2/v2/BleManager/BleManagerBase';
import {IPeakDevice} from '../lib/ble2/v2/PeakDevice/IPeakDevice';
import {ConnectionError} from '../lib/ble2/v2/types';
import {useAppDispatch} from '../lib/hooks';
import {useAppState} from '../lib/hooks/useAppState';
import {useProgress} from '../lib/hooks/useProgress';
import {
  connectedPeakSelector,
  currentDeviceFirmwarePendingSelector,
  currentDeviceIdSelector,
} from '../lib/redux/bleSlice';
import {
  bleDisconnectDevice,
  bleReconnectDevice,
  bleScanAndConnect,
} from '../lib/redux/thunk';
import {BackgroundTimerTask} from '../shims/backgroundTimerTask';
import {PermissionError} from '../src/services/PermissionError';
import {waitUntilAppLoaded} from '../src/util/waitUntilAppLoaded';
import {createContainer} from './unstated-next';

type ConnectionScreenPhase = ConnectionPhase | 'none' | 'starting';

const onBootloader = async () => {
  NavigationService.instance()?.navigate(Navigators.MainNavigator, {
    screen: Navigators.HomeDrawerNavigator,
    params: {
      screen: Navigators.HomeEmulatedDrawer,
      params: {screen: Screens.FirmwareUpdating},
    },
  });
};

const getError = (
  error: unknown,
): ConnectionError | PermissionError | string => {
  if (!error || typeof error !== 'object')
    return ConnectionError.CONNECTION_ERROR;
  if (!('message' in error)) return ConnectionError.CONNECTION_ERROR;

  switch (error.message) {
    case ConnectionError.DEVICE_NOT_FOUND:
    case ConnectionError.IOS_BLUETOOTH_DISABLED:
    case ConnectionError.IOS_CONNECTION_NOT_FOUND:
    case ConnectionError.PAIRING_ERROR:
    case ConnectionError.IOS_DEVICE_FORGOTTEN:
    case ConnectionError.USER_CANCELLED:
    case ConnectionError.WEB_USER_CANCELLED:
    case ConnectionError.IOS_BONDING_ERROR:
    case ConnectionError.ANDROID_BONDING_ERROR:
    case ConnectionError.IN_BOOTLOADER_STATE:
    case PermissionError.LocationDisabled:
    case PermissionError.LocationCanceled:
    case PermissionError.LocationDismissed:
    case PermissionError.LocationRequiresAction:
    case PermissionError.BluetoothDisabled:
    case PermissionError.BluetoothDenied:
    case PermissionError.BluetoothCanceled:
    case PermissionError.BluetoothDismissed:
    case PermissionError.BluetoothRequiresAction:
      return error.message;
  }

  return ConnectionError.CONNECTION_ERROR;
};

interface ConnectOptions {
  deviceId?: string;
  timeout?: number;
}

const useConnection = () => {
  const appState = useAppState();

  const deviceId = useSelector(currentDeviceIdSelector);
  const firmwarePending = useSelector(currentDeviceFirmwarePendingSelector);
  const peak = useSelector(connectedPeakSelector);

  const [canReconnect, setCanReconnect] = React.useState(true);
  const [error, setError] = React.useState<Error>();

  const [progress, setProgress, resetProgress] =
    useProgress<ConnectionScreenPhase>({value: 0, data: 'none', duration: 0});

  const dispatch = useAppDispatch();

  const [connecting, setConnecting] = React.useState<string | boolean>(false);
  const [disconnecting, setDisconnecting] = React.useState<string | boolean>(
    false,
  );

  const promise = React.useRef<Promise<IPeakDevice>>();

  const scanOrConnect = React.useCallback(
    async ({deviceId, timeout}: {deviceId?: string; timeout?: number} = {}) => {
      setCanReconnect(true);

      try {
        setError(undefined);
        setConnecting(deviceId ?? true);
        resetProgress({data: 'starting'});

        await dispatch(bleDisconnectDevice()).unwrap();

        if (deviceId) {
          await dispatch(
            bleReconnectDevice({
              peripheralId: deviceId,
              timeout,
              onProgress: setProgress,
              onBootloader,
            }),
          ).unwrap();
        } else {
          await dispatch(
            bleScanAndConnect({onProgress: setProgress, onBootloader}),
          ).unwrap();
        }

        const peak = bleManager.peak;

        if (!peak)
          throw new Error('Peak connected but disconnected afterwards.');

        return peak;
      } catch (error) {
        appLog.error('Connection failed.', {error});

        setError(error as Error);

        throw error;
      }
    },
    [connecting, !!peak],
  );

  const executeOnce = (callback: () => Promise<IPeakDevice>) => {
    if (promise.current) return promise.current;

    promise.current = callback().finally(() => {
      promise.current = undefined;
    });

    return promise.current;
  };

  const connect = React.useCallback(
    (o: ConnectOptions) =>
      executeOnce(() => scanOrConnect(o).finally(() => setConnecting(false))),
    [scanOrConnect],
  );

  const reconnect = React.useCallback(
    async (deviceId: string, attempt = 1): Promise<IPeakDevice> => {
      appLog.info('Reconnect initiated.', {deviceId, attempt, connecting});

      try {
        return await scanOrConnect({deviceId, timeout: 5000});
      } catch (error: any) {
        if (Object.values(PermissionError).includes(error.message)) {
          appLog.error('Reconnect failed with permission error.', {error});
          throw error;
        }

        if (
          [
            ConnectionError.USER_CANCELLED,
            ConnectionError.WEB_USER_CANCELLED,
            ConnectionError.IN_BOOTLOADER_STATE,
          ].includes(error.message)
        ) {
          appLog.error('Reconnect failed with unretriable error.', {error});
          throw error;
        }

        if (attempt >= 3) {
          appLog.error('Reconnect failed.', {error});
          throw error;
        }

        appLog.error('Reconnect failed. Retrying...', {error});

        return reconnect(deviceId, attempt + 1);
      }
    },
    [connecting, scanOrConnect],
  );

  const disconnect = React.useCallback(async () => {
    setDisconnecting(peak?.peripheralId ?? false);
    setCanReconnect(false);

    await dispatch(bleDisconnectDevice())
      .unwrap()
      .finally(() => setDisconnecting(false));
  }, [peak]);

  const preventReconnect = React.useCallback(
    () => setCanReconnect(false),
    [setCanReconnect],
  );

  React.useEffect(() => {
    switch (appState) {
      case 'active': {
        if (firmwarePending) return;
        if (!deviceId || !canReconnect || connecting || peak) return;

        // Reconnect when the app comes to foreground
        executeOnce(() =>
          reconnect(deviceId).finally(() => setConnecting(false)),
        ).catch(() => void 0);

        break;
      }
      case 'background': {
        if (!peak) return;

        // Disconnect when the app stays in the background for a long period
        const timer = BackgroundTimerTask.setTimeout(
          () => disconnect().catch(() => void 0),
          Constants.DISCONNECT_TIMEOUT,
        );

        return () => BackgroundTimerTask.clearTimeout(timer);
      }
    }
  }, [appState, peak]);

  React.useEffect(() => {
    if (!firmwarePending) return;

    // We need to wait, otherwise the initial screen will overwrite this navigation
    waitUntilAppLoaded()
      .then(onBootloader)
      .catch(() => void 0);
  }, []);

  return {
    peak,
    progress,
    resetProgress,
    connect,
    connecting,
    disconnect,
    disconnecting,
    preventReconnect,
    error: error ? getError(error) : undefined,
  };
};

export const Connection = createContainer(useConnection);
