import React from 'react';
import {Linking} from 'react-native';

import {StyledButton} from '../../../components';

interface LinkButtonConfig {
  text: string;
  url: string; // Could also be a deeplink
}

interface Props {
  config: LinkButtonConfig;
}

export const LinkButton: React.FC<Props> = ({config}) => {
  return (
    <StyledButton
      title={config.text}
      textStyle={{fontFamily: 'Roboto-Bold'}}
      onPress={() => Linking.openURL(config.url)}
    />
  );
};
