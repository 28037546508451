import React from 'react';
import {Image, Linking, StyleSheet} from 'react-native';

import {PressableOpacity} from '../../../shims/PressableOpacity';

interface SocialLinkConfig {
  iconSource: string;
  url: string;
  size?: number;
}

interface Props {
  config: SocialLinkConfig;
}

export const SocialLink: React.FC<Props> = ({config}) => {
  return (
    <PressableOpacity onPress={() => Linking.openURL(config.url)}>
      <Image
        source={{uri: config.iconSource}}
        style={[
          styles.icon,
          {width: config.size ?? 40, height: config.size ?? 40},
        ]}
      />
    </PressableOpacity>
  );
};

const styles = StyleSheet.create({
  icon: {
    resizeMode: 'contain',
  },
});
