import React from 'react';
import {
  Dimensions,
  Image,
  ImageStyle,
  Linking,
  StyleSheet,
  Text,
  View,
} from 'react-native';

import {appColors} from '../../../../constants';
import {PressableOpacity} from '../../../../shims/PressableOpacity';

interface Props {
  thumbnail: string;
  title?: string;
  link?: string;
  useFullWidth?: boolean;
  onPress?: () => void;
  thumbnailStyle?: ImageStyle;
}

export const AssetCarouselItem = ({
  thumbnail,
  title,
  link,
  onPress,
  thumbnailStyle,
  useFullWidth = false,
}: Props) => {
  const width =
    Dimensions.get('window').width * (useFullWidth ? 1 : 0.78) -
    (useFullWidth ? 48 : 0);

  const handlePress = React.useCallback(
    () => (onPress ? onPress() : link ? Linking.openURL(link) : undefined),
    [],
  );

  return (
    <View
      style={{
        width,
      }}>
      <PressableOpacity onPress={handlePress}>
        <Image
          source={{uri: thumbnail}}
          resizeMode="cover"
          style={[styles.thumbnail, thumbnailStyle]}
        />
      </PressableOpacity>

      {!!title && <Text style={styles.title}>{title}</Text>}
    </View>
  );
};

const styles = StyleSheet.create({
  thumbnail: {
    borderRadius: 12,
    borderColor: appColors.darkGray,
    borderWidth: 1,
    aspectRatio: 1.7,
  },
  title: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    fontWeight: '400',
    color: appColors.white,
    marginTop: 10,
  },
});
