import type {Asset, Entry, EntryFields} from 'contentful';

import type {WithContentTypeLink} from './WithContentTypeLink';

export interface TypeGuideStepFields {
  title: EntryFields.Symbol;
  image: Asset;
  description: EntryFields.Text;
}

export type TypeGuideStep = Entry<TypeGuideStepFields>;

export function isTypeGuideStep(
  entry: WithContentTypeLink,
): entry is TypeGuideStep {
  return entry?.sys?.contentType?.sys?.id === 'guideStep';
}
