import React from 'react';
import {Image, StyleSheet, Text, View} from 'react-native';

import {whiteBackArrow} from '../../../assets/images';
import {appColors} from '../../../constants';
import {PressableOpacity} from '../../../shims/PressableOpacity';
import {Section} from './Section';

interface Props {
  title: string;
  items: {title: string; onPress: () => void}[];
}

export const SupportItemSection = ({title, items}: Props) => (
  <Section {...{title}}>
    {items.map(({title, onPress}, index, array) => (
      <React.Fragment key={`${title}-${index}`}>
        {index !== 0 && <View style={styles.divider} />}

        <PressableOpacity
          style={[
            styles.container,
            {
              ...(index === 0 && {
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
              }),
              ...(index === array.length - 1 && {
                borderBottomLeftRadius: 10,
                borderBottomRightRadius: 10,
              }),
            },
          ]}
          {...{onPress}}>
          <Text style={styles.title}>{title}</Text>

          <Image source={whiteBackArrow} style={styles.icon} />
        </PressableOpacity>
      </React.Fragment>
    ))}
  </Section>
);

const styles = StyleSheet.create({
  container: {
    backgroundColor: appColors.translucentDarkGray,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    fontWeight: '400',
    color: appColors.white,
    padding: 20,
  },
  icon: {
    width: 14,
    height: 14,
    margin: 20,
    resizeMode: 'contain',
  },
  divider: {
    height: 1,
    backgroundColor: appColors.white,
    opacity: 0.2,
  },
});
