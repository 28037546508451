import type {Asset, Entry, EntryFields} from 'contentful';

import type {WithContentTypeLink} from './WithContentTypeLink';

export interface TypeLinkItemFields {
  title: EntryFields.Symbol;
  displayName: EntryFields.Symbol;
  url: EntryFields.Symbol;
  icon?: Asset;
  description?: EntryFields.Symbol;
  buttonText?: EntryFields.Symbol;
  separator?: EntryFields.Boolean;
}

export type TypeLinkItem = Entry<TypeLinkItemFields>;

export function isTypeLinkItem(
  entry: WithContentTypeLink,
): entry is TypeLinkItem {
  return entry?.sys?.contentType?.sys?.id === 'linkItem';
}
